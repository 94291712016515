<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="helpMenuTopItems.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('KrishaHelpMenu')"
    @cancel="onCancel('KrishaHelpMenu')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'KrishaHelpMenuCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'KrishaHelpMenu',
      pageHeader: 'Редактирование меню справки',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Меню справки',
          route: { name: 'KrishaHelpMenu' },
        },
        {
          text: 'Редактирование меню справки',
        },
      ],
      initialForm: {
        name: null,
        parentId: null,
        link: null,
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      helpMenuTopItems: (state) => state.common.helpMenuTopItems,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Наименование',
            },
            {
              type: 'select',
              key: 'parentId',
              label: 'Родительский элемент меню',
              list: this.helpMenuTopItems.data,
            },
            {
              type: 'text',
              key: 'link',
              label: 'Ссылка',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.getCommonList({ name: 'HelpMenuTopItems' });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
